import {
  Action,
  Reducer,
  Store,
  StoreEnhancer,
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';


import {userReducer} from './redux/reducers/userReducer';
import {dataReducer} from './redux/reducers/dataReducer';
import {uiReducer} from './redux/reducers/uiReducer';
import { AppState } from './redux/app.state';
import { InjectionToken } from '@angular/core';

const initialState = {
  loading: false,
  authenticated: false
};

const reducers = combineReducers({
  userState: userReducer,
  uiState: uiReducer,
  dataState: dataReducer
});
const devtools: StoreEnhancer<AppState> =
  window['__REDUX_DEVTOOLS_EXTENSION__'] ?
  window['__REDUX_DEVTOOLS_EXTENSION__']({trace: true, traceLimit: 25}) : f => f;


export function createAppStore(): Store<AppState> {
  return createStore<AppState, any, any, any>(reducers,compose(devtools));
}

export const AppStore = new InjectionToken('App.store');

export const appStoreProviders = [
   { provide: AppStore, useFactory: createAppStore }
];
