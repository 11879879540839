import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';

import { User } from '../interfaces/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public db: AngularFireDatabase,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public storage: Storage
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        // console.log("authStateSubscribed");
        // console.log(user);
        this.LoggedIn = true;
        this.storage.set('user', JSON.stringify(this.userData));
        localStorage.setItem('user', JSON.stringify(this.userData));

        // JSON.parse(this.storage.get('user'));
      } else {
        this.LoggedIn = false;
        this.storage.set('user', null);
        localStorage.setItem('user', null);
        // JSON.parse(localStorage.getItem('user'));
      }
    });
  }
  public LoggedIn: boolean;

  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  userData: any; // Save logged in user data

  // login(): Observable<boolean> {
  //   return of(true).pipe(
  //     delay(1000),
  //     tap(val => this.isLoggedIn = true)
  //   );
  // }
  async login(email, password): Promise<Observable<boolean>> {
    console.log(email)
    const pop = await this.SignIn(email, password).then(() => {
      console.log('isLoggedIn');
      console.log(this.isLoggedIn());
      return true;
    });

    return of(pop);
    // this.isLoggedIn = true;
    // return of(true).pipe(delay(1000),tap(val => this.isLoggedIn = true));
  }

  logout(): void {
    this.SignOut();
    // this.isLoggedIn = false;
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        this.ngZone.run(() => {
          this.router.navigate([this.redirectUrl || '/apps']);
        });
        // console.log("result");
        // console.log(result);
        this.afAuth.onAuthStateChanged(user => {
          if (user) {
            console.log('authChange');
            console.log(user);
            this.LoggedIn = true;
            this.storage.set('user', JSON.stringify(user));
            localStorage.setItem('user', JSON.stringify(user));

          } else {
            // User is signed out.
            // ...
            console.log('authChange');
            console.log(null);
            this.LoggedIn = false;
            this.storage.set('user', null);
            localStorage.setItem('user', null);
          }
        });

        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    const user = await this.afAuth.currentUser
    // user.sendEmailVerification()
    return user.sendEmailVerification().then(() => {
      this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch(error => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
   isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.emailVerified;
    // return this.storage.get('user').then((data) => {
    //   if(data !== null){
    //     let user = JSON.parse(data)
    //   return  user.emailVerified;
    //   }else{
    //     return false
    //   }
    // })
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then(result => {
        this.ngZone.run(() => {
          this.router.navigate([this.redirectUrl || 'home']);
        });
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    // console.log("user");
    // console.log(user);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userDb = this.db.object(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    userDb.update(userData);
    return userRef.set(userData, {
      merge: true
    });
  }
  async UpdateProfile(userData: User) {
    const user = await this.afAuth.currentUser;

    user
      .updateProfile({
        displayName: userData.displayName,
        photoURL: userData.photoURL
      })
      .then(() => {
        // Update successful.
        this.SetUserData(userData);
      })
      .catch((error) => {
        // An error happened.
        alert(error);
      });
  }
  async UpdateEmail(userData: User) {
    const user = await this.afAuth.currentUser;

    user
      .updateEmail(userData.email)
      .then(() => {
        // Update successful.
        this.SendVerificationMail();
        this.SetUserData(userData);
      })
      .catch((error) => {
        // An error happened.
        alert(error);
      });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userData = null;
      // this.router.navigate(['login']);
    });
  }


}
