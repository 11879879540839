import { Action, createReducer, on } from '@ngrx/store';
import * as uiActions from '../actions/uiActions';

export interface uiState {
  loading: boolean | string | number
  errors?: any[]
}

export const initialUIstate = {
  loading: false
}
export const uiReducer = createReducer<uiState>(
  initialUIstate,
  on(uiActions.loadingUI, (state, { payload }) => ({...state, loading: payload})),
  on(uiActions.setErrors, (state, { errors }) => ({...state, errors: state.errors.concat(errors)})),
  on(uiActions.clearErrors, (state) => ({...state, errors: []}))
)
