import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subject } from 'rxjs';
import { IDocket } from '../interfaces/IDocket';
import { AuthService } from '../auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class DocketListService {
  private docketsSource = new Subject<IDocket[]>();
  public docketsList$ = this.docketsSource.asObservable();
  public docketsList: IDocket[];

  constructor(public db: AngularFireDatabase, public auth: AuthService, public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        const userid = user.uid;
        const fetchlist = this.db
          .list('users/' + userid + '/docketlist/')
          .valueChanges();
        fetchlist.subscribe((list: IDocket[]) => {
          this.docketsList = list;
          this.docketsSource.next(list);
        });
      } else {
        this.docketsList = [];
        this.docketsSource.next([]);
      }
    });
  }
  public saveDocket(docket: IDocket){
    if(this.auth.isLoggedIn()){
      const user = JSON.parse(localStorage.getItem('user'));
      const userid = user.uid;
      const obj = this.db.object('users/' + userid + '/docketlist/' + docket.id + '/');
      obj.update(docket)
    }
  }
  public removeDocket(docket: IDocket){
    if(this.auth.isLoggedIn()){
      const user = JSON.parse(localStorage.getItem('user'));
      const userid = user.uid;
      const obj = this.db.object('users/' + userid + '/docketlist/' + docket.id + '/');
      obj.remove()
    }
  }
  public getDocketsList(): IDocket[]{
    return this.docketsList;
  }
}
