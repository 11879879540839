import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { AuthService } from './auth/auth.service';
import { User } from './interfaces/user';

import { Store } from 'redux';
import { AppStore } from './app.store';
import { AppState } from './redux/app.state';
import { LOADING_UI } from './redux/types';
import { loadingUI } from './redux/actions/uiActions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    apps = [
      {
        title: 'Federal Motion Maker',
        pages: [
          {
            title: 'Search',
            url: '/federal/tabs/search',
            icon: 'search'
          },
          {
            title: 'Dockets',
            url: '/federal/tabs/dockets',
            icon: 'list',
          },
          {
            title: 'Templates',
            url: '/federal/tabs/templates',
            icon: 'documents'
          },
        ]
    },
    {
      title: 'State Motion Maker',
      pages: [
        {
            title: 'New Case',
            url: '/state/tabs/search',
            icon: 'create',
            disabled: true
          },
          {
            title: 'Dockets',
            url: '/state/tabs/dockets',
            icon: 'list',
            disabled: true
          },
          {
            title: 'Templates',
            url: '/state/tabs/templates',
            icon: 'documents',
            disabled: true
          },
      ]
    }
  ]
  loggedIn = false;
  dark = false;
  user: User;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    public authService: AuthService,
    @Inject(AppStore) private store: Store<AppState>
  ) {
    store.subscribe(() => this.readState());
    this.readState();
    store.dispatch(loadingUI())
    this.initializeApp();
  }
readState() {
    const state: AppState = this.store.getState() as AppState;
    this.loggedIn = state.userState.authenticated
  }
  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
    this.authService.afAuth.user.subscribe(user => this.user = user);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.store.dispatch(loadingUI(3));
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/app/tabs/schedule');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
  setRedirectUrl(){
    const url = this.router.url
    this.authService.redirectUrl = url;
  }
}
