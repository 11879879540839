// User reducer types
export const SET_AUTHENTICATED = '[Auth] SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = '[Auth] SET_UNAUTHENTICATED';
export const SET_USER = '[Auth] SET_USER';
export const LOADING_USER = '[Auth] LOADING_USER';

// UI reducer types
export const SET_ERRORS = '[UI] SET_ERRORS';
export const CLEAR_ERRORS = '[UI] CLEAR_ERRORS';
export const LOADING_UI = '[UI] LOADING_UI';
export const LOAD_UI = '[UI] LOAD_UI';

// Data reducer types
export const CLEAR_ENTRIES = '[CL] CLEAR_ENTRIES';
export const CLEAR_SEARCH = '[CL] CLEAR_SEARCH';
export const ADD_DOCKET_ENTRIES = '[CL] ADD_DOCKET_ENTRIES';
export const ADD_DOCKETS = '[CL] ADD_DOCKETS';
export const SET_DOCKET = '[CL] SET_DOCKET';
export const LOAD_DOCKET = '[CL] LOAD_DOCKET';
export const LOAD_DOCKETS = '[CL] LOAD_DOCKETS';
export const LOAD_DOCKET_ENTRIES = '[CL] LOAD_DOCKET_ENTRIES';

export const LOAD_ERROR = '[CL] LOAD_ERROR';
